import React, { useEffect, useState } from 'react';
import logo from '../images/icon.svg';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import MoreIcon from '@material-ui/icons/MoreVert';
import Divider from '@material-ui/core/Divider';
import Tabs from '@material-ui/core/Tabs';
import ExpandMore from '@material-ui/icons/ExpandMore';
import CustomIconButton from './CustomIconButon';
import SearchBar from './GlobalFilter';
import { useAuth0 } from '@auth0/auth0-react';
import * as store from 'store';
import { useHistory } from 'react-router-dom';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import OrdersSummary from '../components/OrdersSummary';
import FilterResults from '../components/FilterResults';
import { useOrdersContext } from '../contexts/OrdersContext';
import { TableInstance } from 'react-table';
import { OrderSummary } from '../types';
import MobileOrdersSummary from '../components/mobile/MobileOrdersSummary';
import { usePermissions } from '../hooks/usePermissions';
import { SpotlightPermission } from '../types/enums';
import clsx from 'clsx';
import NotificationDrawer from './NotificationDrawer';

// import ChatBubbleIcon from '@material-ui/icons/ChatBubbleOutlineOutlined';
// import { ChatContext } from '../contexts/ChatContext';
// import ChatInbox from './Chat/ChatInbox';
// import { Badge } from '@material-ui/core';
// import './Chat/Chat.css';

const useStyles = makeStyles((theme) => ({
  header: {
    width: '100%',
    minHeight: '75px',
  },
  appBar: {
    width: '100%',
    maxWidth: '100vw',
    position: 'fixed',
    color: '#44444F90',
    backgroundColor: 'white',
    borderBottom: '1px solid #E0E0E0',
    [theme.breakpoints.down('sm')]: {
      padding: '8px 0',
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  tabs: {
    minHeight: '95%',
  },
  indicator: {
    backgroundColor: '#57A946',
    marginLeft: 55,
    height: 5,
    maxWidth: 50,
    width: '100%',
    borderRadius: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  menu: {
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  grow: {
    flexGrow: 1,
  },
  badgeColor: {
    color: '#11B0F2',
  },
  summaryWrapper: {
    minHeight: '165px',
  },
  summary: {
    gap: '16px',
    width: '100%',
    padding: '24px',
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr',
    },
    [theme.breakpoints.down('sm')]: {
      padding: '16px',
    },
  },
  filters: {
    objectFit: 'none',
    padding: theme.spacing(2),
    color: theme.palette.text.secondary,
    borderRadius: theme.spacing(1),
  },
  controls: {
    paddingTop: 0,
    [theme.breakpoints.down(768)]: {
      marginBottom: theme.spacing(1),
    },
  },
}));

interface HeaderSelectProps {
  tableInstance?: React.RefObject<TableInstance<{}>>;
  activeTab?: string;
  children?: React.ReactNode;
  isMobile?: boolean;
  summaryData?: OrderSummary;
}

const vendors = [
  { name: 'All', value: 0 },
  { name: 'Onecart', value: 1 },
  { name: 'Woolworths', value: 2 },
  { name: 'Ava', value: 3 },
];

export default function PrimarySearchAppBar(props: HeaderSelectProps) {
  const { vendor, setOrderID, selectedStores, selectedStatus } = useOrdersContext();
  const [anchorEl, setMenuAnchorEl] = useState(null);

  const { isPermitted } = usePermissions();
  const { logout } = useAuth0();
  const history = useHistory();
  const classes = useStyles();

  const handleMenu = (event: any) => setMenuAnchorEl(event?.currentTarget ?? null);
  const { tableInstance, activeTab, summaryData } = props;

  const tabs = ['orders', 'my-orders', 'action-orders', 'shopper-statuses', 'mall-slots'];
  const setFilter = tableInstance?.current?.setFilter;
  const menuId = 'primary-search-account-menu';

  const handleOrderIDChange = (value: string) => setOrderID(value);

  const handlLogout = () => {
    store.clearAll();
    logout({ returnTo: window.location.origin });
  };

  const isUserPermitted = isPermitted([SpotlightPermission.EDIT_ORDER, SpotlightPermission.ASSIGN_AGENT], 'all');

  // const { unreadCount } = useContext(ChatContext);
  //const [openChatInbox, setChatInbox] = useState(false);
  //const handleChatButton = (state: boolean) => setChatInbox(state);

  const menuItems = [
    { label: 'Orders', action: () => history.push('/orders'), device: 'mobile' },
    { label: 'My Orders', action: () => history.push('/my-orders'), device: 'mobile' },
    { label: 'Action Orders', action: () => history.push('/action-orders'), device: 'mobile' },
    { label: 'Shopper Statuses', action: () => history.push('/shopper-statuses'), device: 'mobile' },
    { label: 'Mall Slots', action: () => history.push('/mall-slots'), device: 'mobile' },
    { divider: true },
    { label: 'My Account', action: () => {}, device: 'desktop' },
    { label: 'Log Out', action: handlLogout, device: 'desktop' },
  ];

  useEffect(() => {
    if (setFilter) {
      const selectedVendor = vendors.find((ven) => ven.name === vendor);
      setFilter('orderTypeID', vendor !== 'All' ? selectedVendor?.value : undefined);
    }
  }, [vendor, setFilter]);

  useEffect(() => {
    if (setFilter) setFilter('shopName', selectedStores?.length ? selectedStores : undefined);
  }, [selectedStores, setFilter]);

  useEffect(() => {
    if (typeof setFilter === 'function') {
      const filterRunningLate = selectedStatus.includes(4);
      const field = !filterRunningLate ? 'status' : 'runningLate';
      let payload = !filterRunningLate ? selectedStatus : true;

      if (!!filterRunningLate || !selectedStatus?.length) {
        setFilter('runningLate', undefined);
        setFilter('status', undefined);
      }
      if (!!selectedStatus?.length) return setFilter(field, payload);
    }
  }, [selectedStatus, setFilter]);

  return (
    <>
      <div className={`${classes.grow} ${classes.header}`}>
        <AppBar position="static" className={classes.appBar}>
          <Toolbar>
            <NotificationDrawer/>
            <CustomIconButton edge="start" className={`${classes.menuButton} ${classes.title}`}>
              <img alt="spotlight logo" src={logo} />
            </CustomIconButton>

            <Typography className={classes.title} variant="h6" noWrap>
              Spotlight
            </Typography>
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>
              <Tabs
                className={classes.tabs}
                classes={{ indicator: classes.indicator }}
                value={tabs.indexOf(activeTab ?? '')}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                <Tab label="Orders" onClick={() => history.push('/orders')} />
                <Tab label="My Orders" onClick={() => history.push('/my-orders')} />
                <Tab label="Action Orders" onClick={() => history.push('/action-orders')} />
                <Tab label="Shopper Statuses" onClick={() => history.push('/shopper-statuses')} />
                <Tab label="Mall Slots" onClick={() => history.push('/mall-slots')} />
              </Tabs>
            </div>
            <div className={classes.grow} />
            <SearchBar tableInstance={tableInstance} setOrderID={handleOrderIDChange} />
            <div className={classes.sectionDesktop}>
              {/* <CustomIconButton>
              <Badge badgeContent={0} className={classes.badgeColor} color="secondary">
                <ChatBubbleIcon onClick={() => handleChatButton(true)} />
              </Badge>
            </CustomIconButton> */}

              <IconButton
                edge="end"
                aria-label="user account"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <AccountCircle />
                <ExpandMore />
              </IconButton>
            </div>
            <div className={classes.sectionMobile}>
              <IconButton
                aria-label="show more"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>

        <ContextMenu id={menuId} anchor={anchorEl} onMenuClose={() => handleMenu(null)} items={menuItems} />

        {/* <ChatInbox
        open={openChatInbox}
        closeChatInbox={() => handleChatButton(false)}
        tableInstance={tableInstance}
        {...props}
      /> */}
      </div>
      {isUserPermitted && summaryData && (
        <OrderSummaryContainer tableInstance={tableInstance} summaryData={summaryData} />
      )}
      {!isUserPermitted && summaryData && <MobileOrderSummaryContainer summaryData={summaryData} />}
    </>
  );
}

const OrderSummaryContainer = (props: {
  tableInstance?: React.RefObject<TableInstance<{}>>;
  summaryData: OrderSummary;
}) => {
  const { tableInstance, summaryData } = props;
  const classes = useStyles();

  return (
    <div className={classes.summaryWrapper}>
      <Grid container className={classes.summary}>
        <OrdersSummary tableInstance={tableInstance} ordersSummary={summaryData} />

        <Paper className={classes.filters}>
          <FilterResults />
        </Paper>
      </Grid>
    </div>
  );
};

const MobileOrderSummaryContainer = (props: { summaryData: OrderSummary }) => {
  const { summaryData } = props;
  const classes = useStyles();

  return (
    <div className={classes.summaryWrapper}>
      <Grid container className={clsx(classes.summary, classes.controls)}>
        <MobileOrdersSummary ordersSummary={summaryData} lateOrders={summaryData.runningLate} />
      </Grid>
    </div>
  );
};

function ContextMenu({ id, anchor, onMenuClose, items = [] }: any) {
  const classes = useStyles();

  const onClickMenuItem = (action: any) => {
    typeof action === 'function' && action();
    return onMenuClose();
  };

  return (
    <Menu
      id={id}
      anchorEl={anchor}
      onClose={onMenuClose}
      open={Boolean(anchor)}
      classes={{ list: classes.menu }}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      keepMounted
    >
      {items.map((item: any, index: number) => {
        const { device, label, divider, action } = item;

        const className = device === 'mobile' ? classes.sectionMobile : '';

        if (!!divider) {
          return <Divider className={classes.sectionMobile} key={`divider-${index}`} style={{ margin: '8px 10px' }} />;
        }

        return (
          <MenuItem key={`${label}-${index}`} className={className} onClick={() => onClickMenuItem(action)}>
            {label}
          </MenuItem>
        );
      })}
    </Menu>
  );
}
